.page-content {
  margin-top: 125px;

  @media only screen and (max-width: 600px) {
    margin-top: 0px;
  }
}

.page-content-no-header {
  margin-top: 10px;

  @media only screen and (max-width: 600px) {
    margin-top: 10px;
  }
}
