@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

*,
::after,
::before {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
span {
  padding: 0px;
  margin: 0px;
  outline: none;
  list-style: none;
  transition: 0.5s;
}
span {
  font-size: 0.875em;
}

a {
  text-decoration: none !important;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

button:hover,
button:active,
button:focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none !important;
}

input:hover,
input:active,
input:focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none !important;
}

body,
html {
  font-family: "Montserrat", sans-serif;
  /* height: 100%; */
  padding: 0px;
  margin: 0px;
  position: relative;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-right: 0 !important;
}

.btn {
  padding: 9px 20px;
  font-weight: bold;
  border-radius: 4px;
  transition: all 400ms ease-in-out;
}

.btn-primary {
  /* background:linear-gradient(136deg, #F7BC06 0%, #E9B106 36%, #7C5E03 100%) 0% 0%; */
  /* background: black; */
  background: black;
  border: none;
  color: #fff !important;
  font-size: 12px;
}
.btn-primary:hover {
  /* background: #6d3a07 */
  background: black;
  /* background:linear-gradient(136deg,#7C5E03 0%, #E9B106 36%, #F7BC06 100%) 0% 0%; */
}
.btn-secondry {
  background: black;
  border: none;
  color: #fff;
  font-size: 14px;
}
.btn-secondry:hover {
  color: #fff;
}
header {
  background: black;
}
header .logo {
  width: 203px;
  height: 55px;
}
header .logo a {
  padding: 5px 10px;
  display: block;
}
header .logo img {
  width: 100%;
}
.toggle-bar-icon {
  color: #fff !important;
  font-size: 22px;
}
.header-content {
  display: flex;
  align-items: center;
  padding: 15px;
}
.header-content .brand-logo {
  width: 200px;
  margin-right: 15px;
}
.header-content .brand-logo img {
  width: 100%;
}
.form-group {
  margin-bottom: 1rem;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.header-search {
  flex: 1;
  margin: 0 40px 0 20px;
}

.header-search .form-group {
  position: relative;
  margin: 0;
}
.header-search .header-search-btn,
.drop-menu {
  position: absolute;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}
.drop-menu {
  right: 40px;
  background: transparent;
  border: none;
  color: #000000;
  top: 5px;
}

.heder-right ul,
.heder-right ul li a {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.heder-right {
  display: flex;
  align-items: center;
}
.heder-right ul li:first-child a {
  font-size: 14px;
}
.heder-right ul {
  margin-right: 15px;
}
.heder-right ul li {
  position: relative;
  margin: 0 15px;
}
.heder-right ul li:after {
  position: absolute;
  right: -15px;
  top: 0;
  width: 1px;
  height: 100%;
  content: "";
  background: #fff;
}
.heder-right ul li a img {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 35px;
  height: 35px;
}
.heder-right ul li:first-child a img {
  margin: 0;
}
.heder-right ul li a span {
  font-size: 12px !important;
  font-weight: bold;
}
.heder-right ul li a,
.heder-right ul li a:hover {
  color: #fff !important;
  text-decoration: none;
}
.header-flag img {
  width: 28px;
}
.header-flag a {
  display: flex;
  align-items: center;
}
.header-search-btn .fa-search {
  transform: rotate(90deg);
}
.heder-right ul li a img.arrow-img,
.header-flag img.arrow-img {
  width: 10px;
  margin-left: 5px;
}
.home-banner-card {
  height: 275px;
  overflow: hidden;
}
.home-banner-card img {
  width: 100%;
  height: 100%;
}
.slick-dots {
  bottom: 5px;
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li {
  background: #fff;
  border-radius: 100%;
  height: 9px !important;
  width: 9px !important;
  border: 1px solid #000000;
}
.slick-dots li.slick-active {
  background: #d6d6d6;
}
.slick-dots {
  bottom: 5px !important;
}
.category-section {
  background: black;
  padding: 25px 50px;
}
.category-section .category-slider-card a {
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none;
  align-items: center;
}
.category-section .category-slider-card img {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.category-section .category-slider-card a span {
  margin-top: 10px;
}
.slick-dotted.slick-slider {
  margin-bottom: 0 !important ;
}
.slick-arrow {
  position: absolute;
  top: 50% !important;
  color: #fff !important; /*Problem area*/
  font-size: 25px !important;
  cursor: pointer;
  z-index: 99;
}
.slick-next {
  right: 25px !important;
}
.slick-prev {
  left: 25px !important;
}
.special-offer-section {
  background: white;
}

.container,
.container-fluid {
  width: 100%;
  padding-right: 8px !important;
  padding-left: 8px !important;
  margin-right: auto;
  margin-left: auto;
}
.w-100 {
  width: 100% !important;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-center {
  text-align: center !important;
}
.common-heading h4 {
  color: black;
  font-size: 18px;
  font-weight: 700;
}
.common-heading h3 {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
  margin-top: 10px;
}
.common-heading {
  margin-bottom: 40px;
}
.card-heading h4 {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}
.category-section .slick-arrow {
  top: 30%;
}
.heading-border {
  position: relative;
}
.heading-border:after {
  position: absolute;
  content: "";
  width: 74%;
  background: #e1e0e0;
  height: 2px;
  top: 50%;
  transform: translate(30%, -50%);
}
.card-heading p {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
}
.offer-card {
  background: whitesmoke;
  padding: 30px 20px;
  height: 100%;
  border-radius: 20px;
}
.recommendation-img {
  height: 300px;
  overflow: hidden;
  padding: 0 30px;
}
.recommendation-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recommendation-slider-wrap .slick-arrow {
  color: #c4c4c4 !important;
  font-size: 20px;
  z-index: 99;
}
.recommendation-slider-wrap .slick-prev {
  left: 0 !important;
}
.recommendation-slider-wrap .slick-next {
  right: 0 !important;
}
.recommendation-slider-wrap {
  margin: 20px 0 5px;
}
.product-caption h4 {
  font-size: 14px;
  color: #000;
  font-weight: 700;
}
.product-caption span {
  color: #0c1522;
  font-size: 10px;
}
.buy-now-btn-wrap a {
  display: inline-block;
  font-size: 10px;
  margin-top: 5px;
  padding: 6px 20px;
}
.heading-cta a {
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc !important;
}
.shop-card-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.shop-card-wrap .shop-card {
  height: auto;
  width: 45%;
  margin: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shop-card-wrap .shop-card img {
  width: 100%;
}
.shop-card-wrap .shop-card a p {
  color: #0c1522;
  font-weight: 700;
  position: absolute;
  bottom: 10px;
  font-size: 10px;
  width: 100%;
  left: 0;
}
.shop-now-btn a {
  color: #2b2d2f;
  font-weight: 700;
  margin-left: 10px;
}
.shop-now-btn {
  margin-top: 10px;
}
.vendor-signup {
  background: blue;
  padding: 30px 20px;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.vendor-signup a {
  display: block;
  margin: 0 auto;
}
.vendor-signup h3 {
  margin: 50px 0;
  color: white;
  /* max-width: 150px; */
  text-align: center;
  font-size: 50px;
  /* line-height: 28px; */
  font-weight: bold;
}
.vendor-icon img {
  width: 90px;
  margin: 0 auto;
}
.custom-spacer {
  padding: 8px 5px 10px 5px;
}
.exclusive-collection-content {
  justify-content: center;
}
.advertisement-card {
  width: 252px;
  margin-left: 20px;
}
.advertisement-card img {
  width: 100%;
}
.exclusive-product-img {
  height: 260px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 600ms ease-in-out;
  cursor: pointer;
  /*padding: 0px 25px;*/
}
.exclusive-product-img:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 28%);
  content: "";
  display: none;
}
.exclusive-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
}
.overlay-btn {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  display: none;
}
.overlay-btn .btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 15px;
  font-size: 12px;
}
.exclusive-product-img:hover:before,
.exclusive-product-img:hover .overlay-btn {
  display: block;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.exclusive-product-card {
  margin-bottom: 0;
  position: relative;
  top: 0;
  transition: top 300ms ease-in-out;
}
.exclusive-product-card:hover {
  top: -10px;
}

.star-rating a .fa-star {
  color: #000000;
  font-size: 10px;
  padding-inline-end: 4px;
}
.old-price,
.new-price {
  font-size: 14px;
  font-weight: 600;
  color: #9e9497;
  position: relative;
}
.old-price:after {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #9e9497;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.exclusive-product-details {
  flex-wrap: wrap;
}
.exclusive-product-details {
  margin: 10px 0;
}
.exclusive-product-content {
  margin-bottom: 5px;
}
.exclusive-product-name {
  font-size: 14px;
  color: #0c1522;
  font-weight: 700;
  cursor: pointer;
  text-transform: capitalize;
  padding-right: 10px;
}
.yellow-color {
  color: black !important;
}
.offer-img a {
  display: block;
  height: 100%;
}
.offer-img img {
  width: 100%;
  height: 100%;
}
.offer-img,
.gift-img {
  height: 100%;
}
.gift-section .vendor-signup h3 {
  font-size: 18px;
  margin: 30px 0;
}
.gift-section .vendor-icon img {
  width: 100%;
}
.gift-section.custom-spacer {
  padding-top: 0;
}
.exclusive-collection-section.custom-spacer,
.best-offer-section.custom-spacer {
  padding-bottom: 0;
  padding-top: 20px;
}
.best-offer-section {
  background: white;
}
.best-offer-section .common-heading h3 {
  color: black;
}
.special-offer-card {
  height: 200px;
  overflow: hidden;
}
.special-offer-card img {
  height: 100%;
  width: 100%;
}
.guarantee-card img {
  width: 70px;
  margin-bottom: 20px;
}
.guarantee-card h4 {
  font-size: 18px;
  font-weight: 700;
}
.guarantee-card p {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
}
.guarantee-section.custom-spacer {
  padding-top: 60px;
  padding-bottom: 60px;
}
footer {
  /*original: background: hsl(122, 24%, 25%); */
  /*palette1: background-color: #31363f;*/
  background-color: #454545;
  padding-top: 40px;
}
.newsletter-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newsletter-right {
  flex: 1;
  margin-left: 180px;
}
.newsletter-right .form-group {
  margin: 0;
  position: relative;
}
.newsletter-right .form-group input {
  height: 45px;
  border-radius: 10px;
}
.newsletter-right .btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 45px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  cursor: pointer;
  border-radius: 0 10px 10px 0;
  margin: 0;
}
footer h4 {
  color: #cfb53b;
  font-size: 16px;
}
footer p {
  color: #fff !important;
  font-size: 14px;
}
.alphabetically-wrap,
.find-out-section > div {
  display: flex;
  align-items: center;
}
.find-out-section {
  margin: 40px 0;
}
.find-out-section a {
  font-size: 14px;
  font-weight: 700;
  color: #fff !important;
  padding: 0 10px;
}
.find-out-category span {
  color: #fff !important;
}
.find-out-category {
  margin-top: 10px;
}
.alphabetically-wrap a:last-child {
  padding-right: 0;
}
.quick-link-section ul li a {
  color: #fff !important;
  font-weight: 600;
}
.quick-link-section ul li {
  margin: 10px 0;
}
.footer-payment-section {
  /* original background: hsl(122, 24%, 18%); */
  /*palette1 background-color: #092635; */
  background-color: #131a22;
  padding: 20px;
  border-radius: 11px;
  margin-top: 30px;
}
footer .payment-mode a {
  padding: 10px 2px;
  display: block;
}
footer .payment-mode a img {
  width: 60px;
}
.payment-mode {
  margin-top: 10px;
}
.footer-payent-section p {
  font-weight: 700;
  line-height: 28px;
  margin-top: 20px;
  font-size: 14px;
}
.footer-customer-section {
  margin-top: 20px;
}
.footer-customer-section a,
.address-text {
  font-size: 14px;
  color: #fff !important;
  font-weight: 600;
  margin: 12px 0;
}
.address-text a {
  display: block;
}
.footer-customer-section i {
  color: #cfb53b;
  margin-right: 8px;
}
.footer-customer-section h4 {
  margin-bottom: 20px;
}
.socail-media a {
  background: #fff !important;
  padding: 3px;
  margin: 10px;
  display: flex;
  align-items: center;
}
.socail-media a img {
  width: 20px;
}
.copyright-section {
  font-size: 14px;
  text-align: center;
  color: #fff !important;
  text-transform: uppercase;
  padding: 20px 0;
}
.copyright-section a {
  color: #fff !important;
  text-transform: lowercase;
}
footer .custom-spacer {
  padding-bottom: 0;
}
.custom-tabs-wrap ul {
  display: flex;
}
.custom-tabs-wrap ul li {
  width: 25%;
}
.custom-tabs-wrap {
  background: #fff;
  padding: 20px 10px;
}

.custom-tabs-wrap a h4 {
  font-size: 16px;
  color: #0c1522;
  font-weight: 700;
}
.custom-tabs-wrap a {
  position: relative;
  margin-right: 5px;
  justify-content: center;
}
.custom-tabs-wrap a:after {
  position: absolute;
  bottom: -20px;
  left: 0;
  content: "";
  background: #e9e9e9;
  border-radius: 4px;
  height: 6px;
  width: 100%;
}
.custom-tabs-wrap a.active:after,
.custom-tabs-wrap a:hover:after {
  background: black;
}
.custom-tabs-wrap a p {
  color: black;
  font-size: 12px;
  font-weight: 400;
}
.custom-tabs-content {
  background: whitesmoke;
  padding-bottom: 20px;
}
.custom-tabs-wrap a img {
  width: 70px;
  margin-right: 20px;
}

.custom-tabs-content ul li {
  width: 20%;
  padding: 10px;
}
.custom-tabs-content ul {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px;
}
.custom-tabs-content ul li .exclusive-product-img {
  height: 230px;
}
.custom-tabs-content ul li .exclusive-product-card {
  top: 0px !important;
}
.custom-tabs-content ul li .exclusive-product-card .yellow-color {
  color: #ff6539 !important;
}
.custom-tabs-content .similar-products-list .li {
  width: 20%;
  padding: 10px;
}
.custom-tabs-content .similar-products-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px;
}
.custom-tabs-content .similar-products-list .li .exclusive-product-img {
  height: 230px;
}
.custom-tabs-content .similar-products-list .li .exclusive-product-card {
  top: 0px !important;
}
.custom-tabs-content .similar-products-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px;
}
.custom-tabs-content .similar-products-list .li {
  width: 20%;
  padding: 10px;
}
.custom-tabs-content .similar-products-list .li .exclusive-product-card {
  top: 0px;
}
.custom-tabs-content
  .similar-products-list
  .li
  .exclusive-product-card
  .yellow-color {
  color: #ff6539 !important;
}
.custom-tabs-content .similar-products-list .li .exclusive-product-img {
  height: 230px;
}
.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-left: 0 !important;
}
.btn-secondary {
  background: #bcbcbc;
  color: #fff !important;
  font-size: 10px;
  padding: 9px 20px;
}
.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.category-section .slick-dots {
  display: none !important;
}
.custom-scrollbar {
  scroll-behavior: smooth;
  overflow-y: auto;
  max-height: 500px;
  height: auto;
  overflow-x: hidden;
  /* padding: 0 1rem; */
  /* margin: 0 10px; */
  padding-right: 5px;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  outline: 1px solid slategrey;
  border-radius: 3px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 30%);
  box-shadow: inset 0 0 5px rgb(0 0 0 / 30%);
  border-radius: 3px;
}
.customer-qa-cards {
  margin: 20px 0;
}
.customer-qa-cards h6 {
  font-size: 12px;
  color: #030303;
  margin-bottom: 5px;
}
.customer-qa-cards h6 span {
  margin-left: 5px;
}
.custom-scrollbar {
  scroll-behavior: smooth;
  overflow-y: auto;
  max-height: 500px;
  height: auto;
  overflow-x: hidden;
  padding-right: 5px;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  outline: 1px solid slategrey;
  border-radius: 3px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 30%);
  box-shadow: inset 0 0 5px rgb(0 0 0 / 30%);
  border-radius: 3px;
}
.customer-qa-cards {
  margin: 20px 0;
}
.customer-qa-cards .like-dislike i {
  font-size: 20px;
  color: #000000;
  display: flex;
  align-items: center;
}

.customer-qa-cards .like-dislike i span {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #000000;
  font-weight: normal;
}

.anonymous-text,
.mob-product-price-section,
.mob-user-review {
  display: none;
}

.customer-qa-cards .like-dislike i:nth-child(1) {
  margin-right: 20px;
}

.mob-product-name,
.mob-new-price {
  display: none;
}

.mob-banner-slider,
.product-code,
.mob-size-chart {
  display: none !important;
}
.flex-grow-1 {
  flex-grow: 1;
}

/*Wishlist common css*/
.col-lg-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-lg-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-xl-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-xl-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  margin-bottom: 8px;
}
.form-check input[type="checkbox"],
.form-check input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-label {
  margin-bottom: 0;
}
label {
  display: inline-block;
}
.d-block {
  display: block !important;
}

.css-18lrjg1-MuiCircularProgress-root {
  color: #fff !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.password-visibility {
  font-size: 15px;
  color: #2e2a2a;
  font-weight: bold;
  padding-right: 4px;
}

.resell-banner-title {
  display: block;
  margin-top: 0px;
  margin-left: 20px;
  color: #333333;
  font-size: 1.1em;
  font-weight: bold; /* Add this line to make the font bold */
}

.resell-banner-button {
  font-size: 0.9em !important;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  margin-left: 20px !important;
  color: #007185 !important;
  cursor: pointer;
}

/* GRID MEDIA START FROM HERE */

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .d-md-block {
    display: block !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .d-md-block {
    display: block !important;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .d-xl-block {
    display: block !important;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
/* GRID MEDIA END HERE */
