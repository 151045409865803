a {
  text-decoration: none;
}

.white-para {
  color: #ffffff;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 83px !important;
  left: 650px !important;
} */

.buttonStyles {
  background: #000000 !important;
  color: #f7f7f7 !important;
  padding: 7.5px 12.5px !important;
  border-radius: 4px !important;
  width: max-content;
}

.sellProducts {
  padding: 10px 15px;
}

.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 100;
}

.navbar-collapse {
  width: 517px;
  /* position: fixed; */
  left: 0px;
  background-color: #ffffff;
  display: block;
  z-index: 9999;
  height: 100%;
  top: 0px;
  transition: left 0.5s;
}
.close-menu {
  position: absolute;
  right: 0px;
  top: 15px;
  padding: 5px 15px;
  color: #fff !important;
  z-index: 999;
}

#menu-appbar .css-177ic5c {
  top: 95px !important;
}

.menu-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.bottom-fixed-section-fab-button {
  position: sticky;
  bottom: 0;
  text-align: center;
}

.top-fixed-section-fab-button {
  position: sticky;
  top: 0;
  text-align: center;
  z-index: 1;
}

.top-fixed-section {
  position: sticky;
  top: 0;
}

.top-fixed-section li.user-tab {
  background-color: #262627;
  padding-left: 60px;
}

.top-fixed-section li.user-tab a {
  color: #fff;
  display: flex;
  align-items: center;
}

li.user-tab img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

li.menu-tab,
.inner-menu-section ul li:hover {
  background-color: #efefef;
}

.header-category-menu {
  position: relative;
}

/* .header-select-wrap .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  height: 350px !important;
} */

.MuiMenu-list {
  position: relative;
}

.down-arrow-icon {
  height: 45px;
  width: 45px;
  background: #262627;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.down-arrow-icon .fa-chevron-down {
  font-size: 20px;
  color: #fff;
}

.top-fixed-section li.menu-tab a,
.inner-menu-section ul li a {
  color: #000000;
  text-decoration: none;
}
.header-menu-item {
  display: block !important;
  padding: 5px !important;
}

.inner-menu-section ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.inner-menu-section ul li.menu-heading {
  color: #262627;
  font-size: 16px;
  padding-left: 20px;
}
.inner-menu-section ul li a span {
  font-size: 18px;
  color: #000000;
}

.top-fixed-section ul li {
  display: inline-block;
  line-height: 60px;
  font-size: 20px;
  padding: 0px 20px;
  width: 100%;
  font-weight: bold;
}

.inner-menu-section ul li {
  display: inline-block;
  line-height: 50px;
  padding: 0px 20px 0 40px;
  font-size: 20px;
  color: #030303;
  width: 100%;
  font-weight: bold;
}

.inner-menu-section ul li.menu-active {
  padding: 0 20px;
}

.inner-menu-section ul li.menu-active a {
  color: #262627;
  cursor: pointer !important;
}

body.overflow {
  overflow-y: hidden;
  /* overflow-x: inherit !important; */
}

a.close-menu i {
  font-size: 25px;
}

.inner-menu-section {
  height: calc(100vh - 8rem);
  overflow-y: auto;
}

.search-provider > div {
  position: relative !important;
}

.hamburger-text {
  padding-left: 10px;
  width: 65px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
}
.search-provider ul {
  position: absolute;
  width: 100%;
  background: #fff;
  padding: 10px;
  max-height: 350px;
  overflow-y: auto;
  border-radius: 10px;
}
.search-provider ul li {
  padding: 10px;
  /* background: #f0f0f0; */
  transition: 0.1s;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.search-provider ul li .img-wrap {
  height: 40px;
  width: 40px;
}

.search-provider ul li .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search-provider ul li:hover {
  /* background: linear-gradient(
    136deg
    , #F7BC06 0%, #E9B106 36%, #7C5E03 100%) 0% 0%; */
  background: #262627;
  color: #fff;
  font-weight: 600;
}

.css-1h5ew8f-ComboboxInner {
  top: -4px !important;
}

.sidebar-bottom a {
  display: inline-block;
  line-height: 60px;
  padding: 0px 20px 0 40px;
  font-size: 20px;
  color: #030303;
  width: 100%;
  font-weight: bold;
}

#menu-appbar
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 70px !important;
  left: auto !important;
  right: 129px;
}

#menu-appbar
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  ul {
  padding: 0;
}

#menu-appbar
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  li {
  /* font-size: 14px; */
  display: block;
}

#menu-appbar
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  li:hover {
  background: #262627;
  color: #fff;
}

#menu-appbar ul li {
  padding: 6px 16px !important;
}

/* NEW HEADER CSS START FROM HERE  */
.heder-right ul li a,
.heder-right ul li a span {
  display: block;
  text-align: center;
}
.heder-right ul li {
  margin: 0 20px;
}
.heder-right ul li a {
  position: relative;
}
.heder-right ul li a span {
  font-size: 12px;
  line-height: 13px;
  display: flex;
  justify-content: center;
  left: 0;
  width: 100%;
}
.heder-right ul li a img {
  margin-right: 0;
}
.heder-right ul li:after {
  top: 8px;
  height: 20px;
  right: -20px;
}

.heder-right ul li:last-child::after {
  top: 18px;
}

.heder-right .btn-primary {
  text-align: center;
  margin-left: 10px;
  background: #fff;
  color: #262627 !important;
  text-decoration: none;
  font-size: 16px;
}
.header-search input,
.header-search input.focus-visible-added {
  height: 69px !important;
  top: -8px;
}

.header-search .header-search-btn {
  top: 9px;
  right: 12px;
}
.header-search {
  margin: 0 0 0 20px;
  position: relative;
  top: 10px;
}

.css-1tutrsd-ViewOption {
  padding-bottom: 20px;
}

.css-1xwysw7-ComboboxInner {
  line-height: 2.5 !important;
}

.header-select {
  position: absolute !important;
  right: 65px;
  top: -55px;
  border: none;
  background: transparent;
  color: black;
}

.header-select:focus-visible {
  outline: none !important;
}

.header-select option {
  padding: 10px;
}

.header-select .MuiOutlinedInput-notchedOutline {
  border: none;
}

header .MuiInputLabel-root {
  right: 105px;
  left: auto;
  top: -26px;
}

/* NEW HEADER CSS END HERE  */

@media (max-width: 1199px) {
  .search-provider ul li p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-right: 20px;
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .header-search {
    margin: 0px;
  }
}

@media (max-width: 767px) {
  .inner-menu-section ul li.menu-active,
  .inner-menu-section ul li,
  .top-fixed-section ul li {
    padding: 0 10px;
  }
  .top-fixed-section ul li,
  .inner-menu-section ul li,
  .inner-menu-section ul li a span {
    font-size: 15px;
  }

  a.close-menu i {
    font-size: 20px;
  }

  .header-flag {
    display: none;
  }

  .header-search {
    margin: 0;
  }

  .navbar-collapse {
    width: 280px;
    left: 0px;
  }
  .search-provider ul li {
    padding: 5px 0px;
    transition: 0.1s;
    font-weight: 600;
    font-size: 12px;
  }
  .heder-right ul li a span.header-text {
    display: none;
  }
  .heder-right ul li {
    margin: 0 5px;
  }
  .heder-right .btn-primary {
    font-size: 10px;
    padding: 6px 10px;
    margin-left: 0;
  }
  .header-content {
    padding: 20px 10px;
  }
}

.position-hamburger {
  margin-left: 15px;
}

.CategoryFontStyle {
  white-space: nowrap;
  color: #fff;
  text-overflow: ellipsis;
  font-weight: bolder;
  letter-spacing: 0.4px;
  font-size: 0.85rem;
  line-height: 0;
  margin: 0;
  padding: 18px;
  width: 13%;
}

.special-font-color p {
  color: #ffdab9;
  font-weight: 600;
}

.custom-category {
  white-space: normal;
  width: auto;
  color: #ffdfd7;
  font-weight: bold !important;
  transition: border 0.3s ease;
}

.CategoryFontStyle:not(.custom-category):hover {
  background-color: #fff;
  color: #000;
}

.mega-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  background-color: transparent;
  width: 100%; /* Adjust the width as needed */
}

.CategoryFontStyle:hover .mega-dropdown-menu {
  display: block;
}

.mega-dropdown-menu ul {
  font-family: Arial, sans-serif;
  list-style: none;
  padding: 0;
  margin: 0;
}

.mega-dropdown-menu ul li {
  padding-bottom: 10px;
  margin: 10px 10px 5px 8px;
  font-size: 12px;
  font-weight: light;
}

.mega-dropdown-menu ul li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.mega-dropdown-menu ul li:hover {
  text-decoration: underline;
}

.header-dropdown-grid {
  display: grid;
  grid-template-columns: 25% 25% 47.5%; /* Adjust the widths as needed */
  grid-gap: 30px;
  padding-right: 20px;
}

.header-dropdown-column {
  padding: 10px;
  box-sizing: border-box;
}

.header-dropdown-heading {
  color: #333333;
  margin-bottom: 15px;
  font-size: 16px;
  text-align: left;
}

.header-dropdown-separator {
  margin: 10px 0;
}

.header-dropdown-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-dropdown-column ul li {
  padding: 8px 0;
  font-size: 14px;
  color: black;
  font-weight: lighter;
  text-align: left;
}

.header-dropdown-column ul li:hover {
  text-decoration: underline;
}

.inner-dropdown-menu {
  background-color: white;
  margin: 10px;
  padding: 20px;
  box-shadow: 5px 5px 15px #aaa;
  border-radius: 20px;
}
