.price-inline {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.95rem;
}

.original-price {
  font-size: 0.85rem;
  color: #888;
  text-decoration: line-through;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
  animation-fill-mode: forwards; /* <-- Keeps final state */
}

.sale-price {
  font-size: 1rem;
  color: #e53935;
  font-weight: 600;
  opacity: 0;
}

.animate-sale {
  animation: popIn 0.3s ease-out forwards;
  animation-delay: 0.3s;
  animation-fill-mode: forwards; /* <-- Keeps final state */
}

.discount-percent {
  font-size: 0.85rem;
  color: #e53935;
}

.normal-price {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  60% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
