.shopDetailsContainer {
  margin: -36px 0px 16px 0px;
  padding: 0px 0px 16px 0px;
}

.shopBannerContainer {
  width: 100%;
  text-align: center; /* Center align the content */
}

.shopDetailsHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  padding: 32px;
  border-radius: 4px;
  height: 250px;
  background-color: rgb(239 239 239);
}

.cartTitle{
   display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height as needed */
  max-height: 3em; /* line-height * 2 (for 2 lines) */
  word-wrap: break-word; /* Break words if necessary */
}
.shopBannerImage {
  width: 100%;
  overflow: hidden;
}


.sellerCategoriesContent {
  padding: 61px 32px 32px 32px;
}

.contentOverFlow {
  padding-top: 10px;
  max-height: 400px;
  overflow: auto;
  padding-right: 16px;
}

.shops_filterButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: #4284f3;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.selectedItem {
  font-size: small;
  font-weight: bold;
  color: #000000;
  margin-top: 0.5em;
  cursor: pointer;
}

.unselectedItem {
  font-size: small;
  font-weight: 500;
  color: #000000;
  margin-top: 0.5em;
  cursor: pointer;
}

.sellerHeaders {
  font-weight: bold;
  color: #000000;
  font-size: large;
  margin: 10px 0px;
}

.drawer {
  padding: 25px;
}

.SingleProductLayoutForDesktop {
  margin-bottom: 24px;
}

.SingleProductLayoutForMobileView {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 90%;
}

.cardActionsContainerForShopDetails {
  padding: 0px 8px;
  margin-top: 5px;
}

.single-product-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.ShopFilter-Select-Label {
  transform: translate(12px, 6px) scale(1) !important;
}

.ShopFilter-Checked-Label {
  transform: translate(12px, -9px) scale(0.75) !important;
}

.ShopFilter-Select-Label.Mui-focused {
  transform: translate(12px, -9px) scale(0.75) !important;
}

.ShopFilter-Select {
  height: 35px;
}

.ShopFilter-Grid {
  padding-left: 0px;
  padding-right: 0px;
}

.hideSingleProductLayoutForMobileView {
  width: 100%;
}

.ShopFilter-Select .MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
  height: 40px;
}

@media (min-width: 1001px) {
  .shops_filterButton {
    display: none;
  }
  .drawer {
    display: none;
  }
  .shopDetailsContent {
    padding: 32px;
  }
  .hideSingleProductLayoutForMobileView {
    display: none;
  }
  .shopBannerImageBookShop {
    margin-top: 35px 
  }
}

@media (max-width: 1000px) {
  .largeScreenFilters {
    display: none !important;
  }
  .ShopDetailsWrapper {
    padding: 0px !important;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .tabPanelRoot {
    padding: 5px !important;
  }
  .SingleProductLayoutForDesktop {
    display: none;
  }
  .shopDetailsContainer {
    margin-top: 20px;
  }
  .shopBannerImageBookShop {
    margin-top: 10px
  }
}
